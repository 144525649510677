<template>
    <div>
        <CCard class="p-0">
            <CCardHeader>
                <div class="d-flex">
                    <div class="flex-grow-1">
						<h5>Email Terdaftar</h5>
					</div>
                    <div class="flex-grow-0 ml-10">
                        <CButton class="btn-sm text-white" color="danger" @click="showPopupModal(statePopUpConstant.create)">
                            Tambah Baru
                        </CButton>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <v-data-table
                    :loading="loading"
                    loading-text="Memuat Data..."
                    :headers="modelTable.headers"
                    :options.sync="optionsTable"
                    :server-items-length="modelTable.totalData"
                    :items="modelTable.dataList"
                    :single-expand="true"
                    item-key="emailConfig"
                    show-expand
                    :footer-props="modelTable.footerProps"
                    class="elevation-1">
						<template v-slot:item.is_active="{ item }">
							<div v-if="item.is_active">
								<font-awesome-icon class="text-success" icon="square"/> Aktif
							</div>
							<div v-else>
								<font-awesome-icon class="text-danger" icon="square"/> Tidak Aktif
							</div>
						</template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon v-c-tooltip="'Ubah konfigurasi email'" small class="mr-2 text-warning" @click="showPopupModal(statePopUpConstant.edit, item)">
                                mdi-pencil
                            </v-icon>
							<v-icon v-c-tooltip="'Hapus konfigurasi email'" small class="mr-2 text-danger" @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <span>Data Tidak Ditemukan</span>
                        </template>
                </v-data-table>
            </CCardBody>
        </CCard>

        <!-- Popup Modal & Input Form -->
        <!-- Create -->
        <CModal
			:closeOnBackdrop="false"
			:show.sync="popupModal.isShow">
            <template #header>
                <h5 class="modal-title">{{ popupModal.modalTitle }}</h5>
				<CButtonClose @click="closePopupModal" class="text-gray"/>
            </template>
            <!-- Input Form -->
            <div class="p-1">
                <CInput
                    v-model="modelForm.input.email"
                    label="Email"
                    placeholder="Email"
                    :is-valid="modelForm.validate.email"
                    @input="validateForm('email')" />
				<CInput v-if="isEdit"
					v-model="modelForm.input.newEmail"
					label="Email Baru"
					placeholder="Email Baru"
					:is-valid="modelForm.validate.email"
					@input="validateForm('email')" />
                <CInput
                    v-model="modelForm.input.password"
                    label="Password"
					type="password"
                    placeholder="Password"
                    :is-valid="modelForm.validate.password"
                    @input="validateForm('password')" />
				<div v-if="isEdit" class="mb-20">
					<label class="typo__label" style="margin-bottom:0.9rem">Email Status</label>
					<CInputCheckbox
						label="Aktif"
						:inline="false"
						:checked="modelForm.input.is_active"
						@update:checked="inputCheckEmailActive($event)"/>
				</div>
            </div>
            <template #footer>
                <CButton @click="closePopupModal" class="btn-sm">Batal</CButton>
                <CButton @click="saveItem" color="success" class="btn-sm" :disabled="modelForm.submitBtn.disabled">
                    <font-awesome-icon v-if="modelForm.submitBtn.loader" icon="spinner" spin/> {{ modelForm.submitBtn.text }}
                </CButton>
            </template>
        </CModal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue-multiselect/dist/vue-multiselect.min.css';
const avatar = require('@/assets/img/avatar.svg')
export default {
    props: {
        isDispositionProp: {
            type: Boolean,
            default: false
        }
    },
    data () {
		const modelTable = {
			headers: [
				{ text: 'Email', value: 'email', align: 'center', sortable: true },
				{ text: 'Status', align: 'center', sortable: true, value: 'is_active' },
				{ text: 'Aksi', value: 'actions', align: 'center', sortable: false }
			],
			footerProps: {
				'disable-items-per-page': true,
				'items-per-page-text': 'Data Ditampilkan'
			},
			totalData: 0,
			dataList: [],
			expandedTable: []
		}
		const popupModal = {
			isShow: false,
			modalTitle: 'Tambah Email Kotak Masuk'
		}
		const modelForm = {
            editedIndex: -1,
            flowOption: [],
			input: {
                id_email: null,
                email: null,
				password: null,
				is_active: null
			},
			defaultValue: {
                id_email: null,
                email: null,
				password: null,
				is_active: null
			},
			validate: {
                email: null,
				password: null
			},
			submitBtn: {
				loader: false,
                text: 'Simpan',
				disabled: true
			}
		}
		const statePopUpConstant= {
			create: 'create',
			edit: 'edit'
		}

		return {
			loading: true,
			avatar,
			modelTable,
			optionsTable: {},
			popupModal,
			modelForm,
			statePopUp: 'create',
			statePopUpConstant,
			isEdit: false
		}
	},
	computed: {
        ...mapState('auth', {
			authDivisionId: 'satker_id'
        })
	},
	watch: {
		optionsTable: {
			deep: true,
			handler () {
				this.getDataList()
			}
		}
    },
	mounted () {
		this.getDataList()
	},
	methods: {
        inputCheckEmailActive (value) {
			this.modelForm.input.is_active = value
		},
		// Fetch Data
		getDataList () {
			this.loading = true
			return new Promise((resolve, reject) => {
				const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

				this.$store.dispatch({
                    type: 'email/get',
                    pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage
                    },
                    filter: {
                        satkerId: this.authDivisionId
                    }
                }).then( res => {
					let items = []
					let total = 0
					items = res.result
					total = res.total_count
					this.modelTable.dataList = items
					this.modelTable.totalData = total
				}).finally( () => {
					this.loading = false
				})
			})
		},
		showPopupModal (state, item = null) {
			if(state == this.statePopUpConstant.edit) {
				this.isEdit = true 
			} else {
				this.isEdit = false
			}
			console.log(item)
			this.popupModal.modalTitle = (item != null) ? 'Ubah Email Kotak Masuk' : 'Tambah Email Kotak Masuk'
			this.modelForm.editedIndex = (item != null) ? item.id_email : -1
			console.log(this.modelForm.editedIndex)
			if (item) {
				this.modelForm.input.id_email = item.id_email
				this.modelForm.input.email = item.email
				this.modelForm.input.is_active = item.is_active
				this.validateForm('email')
			}
			this.popupModal.isShow = true
		},
		closePopupModal (refreshState = false) {
			this.popupModal.isShow = false
			this.modelForm.flowOption = []
			this.modelForm.input = Object.assign({}, this.modelForm.defaultValue)
			this.modelForm.validate = {
                email: null,
                password: null
			}
			this.modelForm.submitBtn.disabled = true
			if (refreshState === true)
				this.getDataList()
		},
		validateForm (field) {
			if (field == 'email') {
				if (this.modelForm.input.email != '') {
					this.modelForm.validate.email = true
				} else {
					this.modelForm.validate.email = false
				}
			}
			
			if (field == 'password') {
				if (this.modelForm.input.password != '') {
					this.modelForm.validate.password = true
				} else {
					this.modelForm.validate.password = false
				}
            }
            
            if (this.modelForm.validate.email &&
				this.modelForm.validate.password)
			{
				this.modelForm.submitBtn.disabled = false
			} else {
				this.modelForm.submitBtn.disabed = true
			}
		},
		saveItem () {
			this.modelForm.submitBtn = {
				disabled: true,
				loader: true,
                text: 'Memproses...'
			}
			const url = (this.modelForm.editedIndex != -1) ? 'email/update' : 'email/store'
			
			var messageSuccess = 'Sukses menambah data'
			if(this.isEdit) {
				messageSuccess = 'Sukses mengubah data'
			}
			
			this.$store.dispatch({
				type: url,
				formInput: this.modelForm.input
			}).then( res => {
				if(res.is_fail) {
					this.$toasted.error(res.error).goAway(3000)
					this.modelForm.submitBtn = {
						disabled: false,
						loader: false,
						text: 'Simpan'
					}
				} else {
					this.$toasted.success(messageSuccess).goAway(3000)
					this.isEdit = false	
					this.closePopupModal(true)
				}
			}).catch( err => {
				console.log('Failed to store/update email config')
				console.log(err)
				this.modelForm.submitBtn = {
					disabled: false,
					loader: false,
					text: 'Simpan'
				}
				var message = err.response.data.error
				this.$toasted.error(message).goAway(3000)
			})
		},
		deleteItem (item) {
			this.$confirm({
                auth: false,
                message: 'Anda yakin ingin menghapus data ini?',
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch({
							type: 'email/deleteEmailConfig',
							formInput: item
						}).then( res => {
							this.getDataList()	
							this.$toasted.success('Sukses menghapus data email').goAway(3000)
						}).catch( err => {
							console.log(err)
							this.$toasted.error	('Gagal menghapus data email, silahkan coba kembali!').goAway(3000)
						})
					}
				}
			})
		}
	}
}
</script>